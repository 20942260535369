<template>
  <section>
    <router-view v-slot="{ Component }">
      <transition name="el-fade-in-linear">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
  </section>
</template>

<script>
export default {
//   name: "Model",
//   computed: {
//     key() {
//       return this.$route.path;
//     },
//   },
//   provide() {
//     return {
//       reload: this.reload,
//     };
//   },
//   data() {
//     return {
//       isRouterAlive: true,
//     };
//   },
//   methods: {
//     reload() {
//       this.isRouterAlive = false;
//       setTimeout(() => {
//         this.isRouterAlive = true;
//       }, 300);
//     },
//   },
};
</script>
