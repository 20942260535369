<template>
  <div class="footerBox">
    <div>闽ICP备2021005280</div>
  </div>
</template>

<script>
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onActivated,
  onDeactivated,
} from "vue";
import { ref, reactive, toRefs, watch, computed } from "vue";
import { useStore, mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { useRouter } from "vue-router";
export default {
  components: {},
  setup() {
    const _this = getCurrentInstance().proxy;
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      count: 0,
      vrUrl: false,
      waterEntityEnd: false,
      captchaImg: "",
      type: "账号登录",
      loginForm: {
        idcard: "",
        password: "",
        code: "",
      },
      is: false,
      loginRules: {
        idcard: [
          { required: true, message: "请填写手机号码", trigger: "change" },
        ],
        password: [
          { required: true, message: "请填写登录密码", trigger: "change" },
        ],
        code: [{ required: true, message: "请填写验证码", trigger: "change" }],
      },
      user: "",
      region: "",
    });

    onMounted(async () => {});
    return {
      state,
      //方法
    };
  },
};
</script>
<style lang="less" scoped>
.footerBox {
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 0 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #909399;
  line-height: 20px;
  width: 100%;
  box-sizing: border-box;
  height: 80px;
  background: rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px -1px 0px 0px rgba(128, 128, 128, 0.315);
  -moz-box-shadow: 0px -1px 0px 0px rgba(128, 128, 128, 0.315);
  box-shadow: 0px -1px 0px 0px rgba(128, 128, 128, 0.315);
  flex-shrink: 0;
}
</style>