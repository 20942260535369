<template>
  <div>
    <Header v-if="$route.path !='/login' &&  $route.path !='/test'" />
    <Model />
    <!-- <Footer /> -->
  </div>
</template>

<script>
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onActivated,
  onDeactivated,
} from "vue";
import { refreshToken } from "@/api";
import Top from "@/components/Top";
import Bottom from "@/components/Bottom";
import Model from "@/components/Model";
import Header from "@/components/header";
import Footer from "@/components/footer";
import { ref, reactive, toRefs, watch, computed } from "vue";
export default {
  components: {
    Model,
    Top,
    Bottom,
    Footer,
    Header,
  },
  setup() {
    const _this = getCurrentInstance().proxy;
    const state = reactive({
      count: 0,
      refreshTime: false,
    });
    const refreshToken1 = () => {
      state.refreshTime = setInterval(() => {
        let refresh_token = _this.getCookie("saber-refresh-token");
        refreshToken({
          tenantId: "000000",
          refresh_token,
          grant_type: "refresh_token",
          scope: "all",
        });
      }, 10000);
    };
    onMounted(() => {
      // 定时检测token
      // refreshToken1()
    });

    return {
      state,
    };
  },
};
</script>

<style  scoped>
</style>