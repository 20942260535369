<template>

    <div @click="switch1()" class="top">
      <van-button type="primary">主要按钮</van-button>
    </div>
</template>

<script>
import {
  getCurrentInstance,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onActivated,
  onDeactivated,
} from "vue";
import { ref, reactive, toRefs, watch, computed } from "vue";
import { useStore, mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { useRouter } from "vue-router";

export default {
  setup() {
    const _this = getCurrentInstance().proxy;
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      count: 1,
    });
    const switch1 = () => {
      let value = _this.$route.path;

      console.log(value);
      state.count += 1;
      if (state.count > 4) {
        state.count = 1;
      }
      store.commit("setTest", state.count);
      switch (state.count) {
        case 1:
          router.push({ path: "/Router1" });
          break;
        case 2:
          router.push({ path: "/Router2" });
          break;
        case 3:
          router.push({ path: "/Router3" });
          break;
        case 4:
          router.push({ path: "/Router4" });
          break;
        default:
          break;
      }
    };

    return {
      ...toRefs(state),
      switch1,
    };
  },
};
</script>

<style lang="less" scoped>
.top {
  width: 200px;
  height: 50px;
  background-color: coral;
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translate(-50%, 0);
  z-index: 10;
}
</style>