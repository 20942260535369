<template>

        <div class="top">头部</div>

</template>

<script>
import { reactive, toRefs } from 'vue'

export default {
    setup () {
        const state = reactive({
            count: 0,
        })
    
        return {
            ...toRefs(state),
        }
    }
}
</script>

<style  scoped>
.top{
    width: 200px;
    height: 50px;
    background-color: coral;
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translate(-50%,0);
    z-index: 10;
}
</style>